import accountServices from '../../../services/account'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import {signIn} from '@/utils/auth.js'
import {signOut} from '@/utils/auth.js'
export default {
    data() {
        return {
            payload: {}
        }
    },
    created(){
        console.clear();
        if (this.$route && this.$route.query.y && this.$route.query.e) {
            this.payload = {
                //token: this.$route.query.c,
                email: this.$route.query.e,
                code: this.$route.query.y,
                password: null,
                confirm_password: null,
            }
        } else {            
            this.$router.push({name: 'Login'})
        }
    },
    methods: {
        verifyAccount() {
            //debugger
            Auth.forgotPasswordSubmit(this.payload.email, this.payload.code, this.payload.password)
                .then(data => {
                    console.clear();
                    this.$router.push({name: 'SuccessPage', params: {
                        title: 'Password Changed!',
                        subTitle: 'Your password has been changeed successfully.',
                        icon:'far fa-check-circle'
                    }})
                }
                     )
                .catch(err => {
                    console.clear();
                    //debugger
                    if (err.message == 'Invalid code provided, please request a code again.')
                    {
                        this._showToast("The Token is not valid or Expired.", { variant: 'danger',title:'Error' })
                    }
                    //console.log(err)
                });
        }
    }
}